import React, { Fragment, useEffect } from "react"
import { navigate } from "gatsby"

import "../styles/submissionPageStyles.scss"

import ContactFormRobot from "../images/ghost-service-scenario-5.svg"

const SubmissionPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/")
    }, 6000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Fragment>
      <div className="ap-submission-page-container">
        <h1>Thanks for your enquiry!</h1>
        <h3>I'll respond within 24 hours</h3>
        <div className="ap-submission-page-robot-container">
          <img src={ContactFormRobot} alt="Contact Form Robot" />
        </div>
      </div>
    </Fragment>
  )
}

export default SubmissionPage
